.WalletComponent{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ConnectWallet{
    cursor: pointer;
    height: 36px;
    line-height: 36px;
    width: 180px;
    background: #0d1116;
    border: 2px solid #363b42;
    text-align: center;
    color: #fff;
    border-radius: 4px;
}
.ant-select .ant-select-arrow{
    color: #fff;
}


@media only screen and (max-width: 576px) {
    .Wallet{
        flex-direction: column;
        align-items: start;
    }
    .WalletComponent{
        width: 100%;
        font-size: 14px;
    }
    .WalletInfo{
        width: 100%;
    }
    .ConnectWallet{
        width:100%;
    }
}
